import { Link } from "react-router-dom"
import Moment from 'moment';
import { useAppSelector } from "../../redux/hooks";

export const SinglePopulatePost = ({ post }: any) => {
  const { lng } = useAppSelector((store) => store.language)
  const { title, publishedDate, old_cover } = post.attributes
  const currentImg = post.attributes?.cover?.data?.attributes?.formats?.small?.url || post.attributes?.cover?.data?.attributes?.formats?.thumbnail?.url

  return (
    <li className="d-flex flex-wrap justify-content-between align-items-center">
      <figure>
      <img src={currentImg ? process.env.REACT_APP_BASE_URL + currentImg : process.env.REACT_APP_OLD_IMAGES_FOLDER + old_cover} alt={title} />
      </figure>
      <div className="entry-content">
        <h3 className="entry-title">
          <Link to={`${`/${lng}/articles/${post.id}`}`}>{title}</Link>
        </h3>
        <div className="posted-date">{Moment(publishedDate).format('DD-MM-YYYY')}</div>
      </div>
    </li>
  )
}
