import { useEffect } from "react"
import { Sidebar } from "../Sidebar"
import "./SingleNews.css"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { getArticle, getArticleGallery, getCategory } from "../../redux/actions"
import { ImageGallery } from "../ImageGallery"
import { Helmet } from 'react-helmet';
import { dateFormat, getArticleLocalization } from "../../Api"
import { ImageGalleryNew } from "../ImageGalleryNew"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { get } from "jquery"

export const SingleNews = ({ article, id }: any) => {
  const { locale } = useParams()
  const navigate: any = useNavigate();
  // let { article } = useAppSelector((state: any) => state.getArticle)

  const [t, i18n] = useTranslation();
  const changeLng = () => {
    i18n.changeLanguage(locale)
  }
  const { lng } = useAppSelector((store) => store.language)
  let singleNewsData = article.data

  useEffect(() => {
    changeLng()
    // dispatch(getArticle({ id, locale }))
  }, [])


  if (lng != locale) {
    singleNewsData = getArticleLocalization(singleNewsData, lng)
    navigate(`/${lng}/articles/${singleNewsData.id}`, { replace: true })
  }

  const { title, publishedAt, publishedDate, gallery, old_cover, old_gallery, cover } = singleNewsData?.attributes
  
  // const currentImg = cover?.data?.attributes?.formats?.medium?.url || cover?.data?.attributes?.formats?.small?.url

  const currentImg = singleNewsData.attributes?.cover?.data?.attributes?.formats?.small?.url || singleNewsData.attributes?.cover?.data?.attributes?.formats?.thumbnail?.url


  const createMarkup = () => {
    if (singleNewsData.attributes?.blocks) {
      return { __html: singleNewsData.attributes?.blocks[0]?.body }
    }
  }

  const category = useAppSelector(
    (store: any) => store.category.data?.attributes
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    // dispatch(getArticleGallery(id))
    dispatch(getCategory(id))
  }, [id])

  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta property="og:image" content={currentImg ? process.env.REACT_APP_BASE_URL + currentImg : process.env.REACT_APP_OLD_IMAGES_FOLDER + old_cover}></meta>
    </Helmet>
    <div className="singleNewsWrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="news-image">
                <img
                  src={currentImg ? process.env.REACT_APP_BASE_URL + currentImg : process.env.REACT_APP_OLD_IMAGES_FOLDER + old_cover} alt={title}
                  className="w-100" />
              </div>

              <div className="col-12 col-md-6 col-lg-6 mt-4 mt-lg-0 singleNewsTitle">
                <h3 className="entry-title w-100 m-0">
                  <p>{title}</p>
                </h3>
                <div className="posted-date">
                  <p>{dateFormat(publishedDate)}</p>
                </div>
              </div>
              <div className="entry-content">
                <div className="m-0" dangerouslySetInnerHTML={createMarkup()} />
              </div>
              <div className="imageGallery col-lg-12 col-md-10">
                <ImageGalleryNew galleryID="my-test-gallery" images={gallery?.data} />
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div></>
  )
}
